.steps-nav-container {
    float: right;
    .to-rem ( margin-right, 18 );
    text-align: center;

    .steps-nav {
        list-style: none;
        user-select: none;

        li {
            display: inline-block;
            margin-right: -58px;

            &.step-icon {
                position: relative;
                z-index: 10;
                width: 100px;
                vertical-align: top;

                .fa-circle {
                    color: #999;
                }

                .fa-circle-dot {
                    color: @primary-colour;
                }

                .fa-circle-check {
                    color: @pos-btn-bg-colour;
                }

                .step-stack-icon {
                    padding: 2px;
                    width: 46px;
                    height: 44px;
                    border-radius: 50%;
                    background-color: #FFF;
                }

                .step-name {
                    display: block;
                    margin: 5px auto;
                    width: 60px;
                    color: @secondary-colour;
                    font-size: 12px;
                }
            }

            &.step-join {
                position: relative;
                top: -2px;
                width: 60px;
                border-bottom: 7px solid #FFF;
            }
        }
    }

    .step-btn {
        position: relative;
        .to-rem ( width, 38 );
        .to-rem ( height, 38 );
        .to-rem ( margin, 3 40 );
        .to-rem ( padding, 4 );
        border: none;
        border-radius: 50%;
        color: #FFF;
        cursor: pointer;

        i, svg {
            cursor: pointer;
        }

        &.back-step-btn {
            background-color: @neg-btn-bg-colour;
        }

        &.next-step-btn, &.submit-steps-btn {
            background-color: @pos-btn-bg-colour;
        }
    }
}

.fa-1-5x {
    font-size: 1.5em;
    line-height: 1.52em;
}
.fa-stack-1-5x {
    font-size: 1.5em;
}

.highlight-box-1 {
    border: 1px solid #06C;

    background-color: #6CF;
}

.custom-dropdown {
    -webkit-appearance: none;

    background-image: url('/assets/graphics/dropdown-arrow-666.png');
    background-repeat: no-repeat;
    background-position: center right 9px;
}
